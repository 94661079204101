<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card outlined>
          <v-card-title class="title" v-if="filterType === 'guardian'">
            <v-icon style="margin-right: 5px; margin-top: -5px">cake</v-icon>
            Guardian's Birthday List
          </v-card-title>
          <v-card-title class="title" v-else>
            <v-icon style="margin-right: 5px; margin-top: -5px">cake</v-icon>
            Staff's Birthday List
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="this.form.items.data"
            :loading="form.loading"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr :key="index">
                <td>{{ ++index }}</td>
                <td class="text-xs-left">
                  {{ item.name }} <br />
                  <span
                    v-if="filterType !== 'guardian'"
                    class="grey--text text--lighten-1"
                  >
                    {{ item.role }}
                  </span>
                </td>
                <td class="text-xs-left">{{ item.email }}</td>
                <td class="text-xs-left">{{ item.primary_mobile }}</td>
                <td class="text-xs-left">{{ item.dob }}</td>
                <td>
                  <v-tooltip left v-if="item.primary_mobile">
                    <template v-slot:activator="{ on }">
                      <v-icon
                        style="cursor: pointer; color: #1976d2"
                        v-on="on"
                        @click="smsInfo(item)"
                        :disabled="!smsServiceStatus"
                        >sms
                      </v-icon>
                    </template>
                    <span>Send SMS</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="smsDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Send SMS </span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form
              ref="smsForm"
              @submit.prevent="sendSMS"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="sendSMS"
              lazy-validation
            >
              <v-layout row wrap pt-10>
                <v-flex xs12>
                  <v-text-field
                    outlined
                    dense
                    autocomplete="off"
                    label="Mobile Number*"
                    required
                    class="pa-0 pl-2"
                    v-model="smsForm.mobile"
                    name="mobile"
                    hint="Mobile Number should be 10 digits."
                    :error-messages="form.errors.get('mobile')"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-sms-input-field
                    v-model="smsForm.message"
                    :hide-balance="true"
                    :show-alert="false"
                    :default-placeholder="defaultPlaceholder"
                    :default-sms="smsForm.message"
                  ></v-sms-input-field>
                  <!-- <v-textarea
                    outlined
                    dense
                    label="Message*"
                    :rows="6"
                    v-model="smsForm.message"
                  />
                  <p v-if="characterUsed > 0">
                    Character Used : {{ characterUsed }}, Parts :
                    {{ smsForm.sms_length }}
                  </p> -->
                </v-flex>
                <v-flex xs-12>
                  <v-sms-stats
                    :smsQueue="sms_queue"
                    :balance="balance"
                    :ratePerSMS="rate_per_sms"
                    :birthdayUse="true"
                  ></v-sms-stats>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(smsDialog = false), smsForm.reset()"
              >Close</v-btn
            >
            <v-btn
              color="success"
              text
              @click="sendSMS"
              :disabled="
                !(
                  smsForm.mobile &&
                  smsForm.message &&
                  smsForm.mobile.length === 10
                )
              "
              >Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import SMS from "@/library/SMS";

export default {
  mixins: [Mixins],
  data: () => ({
    balance: 0,
    sms_queue: 0,
    rate_per_sms: 0,
    smsServiceStatus: false,
    smsDialog: false,
    characterUsed: 0,
    pagination: {
      rowsPerPage: 10,
    },
    form: new Form(
      {
        name: "",
        description: "",
        company_id: "",
        parent_id: "",
      },
      "/api/user/birthday-list"
    ),
    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      { text: "Name", value: "name", align: "left", sortable: false },
      { text: "Email", value: "email", align: "left", sortable: false },
      { text: "Mobile", value: "mobile", align: "left", sortable: false },
      { text: "DOB", value: "dob", align: "left",width:105, sortable: false },
      {
        text: "Send SMS",
        value: "guardian",
        align: "left",
        width: "160px",
        sortable: false,
      },
    ],
    smsForm: new Form(
      {
        sms_type: "s",
        msg_type: 1,
        // file: '',
        mobile: "",
        message: "",
        sms_length: 1,
        sms_for: "birthday",

        name:"",
        dob:"",
      },
      "/api/sms/queue"
    ),
    defaultPlaceholder: [
      {
        text: "Name",
        placeholder: "[[name]]"
      },  
      {
        text: "Date Of Birth(BS)",
        placeholder: "[[date_of_birth_BS]]",
      },
      {
        text: "Date Of Birth(AD)",
        placeholder: "[[date_of_birth_AD]]",
      },
      {
        text: "Years Old",
        placeholder: "[[years_old]]",
      },
    ],
    sms: new SMS(),
    pagination: {
      rowsPerPage: 10,
    },
  }),
  props: {
    filterType: {
      default: "guardian",
    },
  },

  mounted() {
    if (this.$auth.getServices("SMS")) this.smsServiceStatus = true;
    else this.smsServiceStatus = false;
  },
  methods: {
    get() {
      let extraParams = "userType=" + this.filterType;
      this.form.get(null, this.queryString(extraParams)).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    smsInfo(item) {
      console.log(item);
      if (item.primary_mobile !== "null")
        this.smsForm.mobile = item.primary_mobile;
      this.smsDialog = true;
      this.smsForm.name = item.name;
      this.smsForm.dob = item.dob;
      this.getSmsDefaultMessage();
    },
    sendSMS() {
      this.smsForm.fireFetch = false;
      this.smsForm
        .store()
        .then((data) => {
          this.smsDialog = false;
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.data.message,
            status: "error",
          });
        });
    },
    getSmsDefaultMessage(){
      let type = '';
      this.filterType === 'guardian' ? type ='guardian birthday' : type = 'staff birthday'
      this.$rest.get(
          "/api/sms/get-default-message?type="+type
        )
      .then((res)=>{
        this.smsForm.message = res.data.data[0].message
      }).catch((err)=>{

      })
    }
  },
  
  watch: {
    filterType: function () {
      this.get();
    },
    pagination: function () {
      this.get();
    },
  },
};
</script>
